<template>
    <div class="vipOrder">
        <t_header :isUserInfo="isUserInfo" @setTodos="getTodos"></t_header>
        <div class="vipOrder_header">
            <img src="@/assets/ask/vipPage_banner.png" alt="">
        </div>
        <div class="vipOrder_main">
            <div class="vipOrder_main_header">
                <img src="@/assets/course/vipPage_success.png" alt="">
                <span>订单已提交，请尽快完成付款！</span>
            </div>
            <div class="vipOrder_main_wire">
                <img src="@/assets/course/wire.png" alt="">
            </div>
            <div class="vipOrder_main_orderInfo">
                <div class="vipOrder_main_orderInfo_item">
                    <p>订单名称</p><p>
                        <span>{{title}}</span>
                        <img src="@/assets/course/VIP.png" alt="">
                    </p>
                </div>
                <div class="vipOrder_main_orderInfo_item">
                    <p>付款金额</p><p>¥{{vipInfo.price}}</p>
                </div>
                <div class="vipOrder_main_orderInfo_item">
                    <p>会员有效期</p><p>{{newDate}} 至 {{endDate}}</p>
                </div>
            </div>
            <div class="vipOrder_main_price">¥{{vipInfo.price}}</div>
            <div class="vipOrder_main_payType">
                <div class="vipOrder_main_payType_title">支付方式</div>
                <div class="vipOrder_main_payType_list">
                    <div class="vipOrder_main_payType_list_item" v-for="(item,index) in payTypeList" :key="index" @click="tabPayType(item)">
                        <img src="@/assets/course/coupon_checked.png" alt="" class="checked" v-if="payTypeIndex==item.id">
                        <img src="@/assets/course/Alipay.png" alt="" style="width:22px;height:22px;" v-if="item.id=='1'">
                        <img src="@/assets/course/WX.png" alt="" style="width:22px;height:22px;" v-if="item.id=='2'">
                        <img src="@/assets/course/JD.png" alt="" style="width:22px;height:22px;" v-if="item.id=='3'">
                        <img src="@/assets/course/E-bank.png" alt="" style="width:28px;height:18px;" v-if="item.id=='4'">
                        <span>{{item.name}}</span>
                    </div>
                </div>
                <div class="vipOrder_main_payType_notice">
                    <!-- 注：支持花呗分期 -->
                </div>
            </div>
            <div class="vipOrder_main_other">
                <el-checkbox v-model="checked">同意</el-checkbox><span @click="goumaixieyi" style="cursor:pointer;font-size:14px;">《问答会员协议》</span>
                <el-button type="primary" :disabled="checked?false:true" @click="pay()">确认支付</el-button>
            </div>
            <!-- <div class="vipOrder_main_issue">
                <span>支付遇到问题？</span>
            </div> -->
        </div>
        <!-- 付钱弹窗 -->
        <div class="dialog">
            <el-dialog 
                title="微信扫码支付"
                :visible.sync="centerDialogVisible"
                width="570px"
                center>
                <!-- <p class="dialog_between"><span>课程名称：</span><span>{{courseMap.name}}</span></p>
                <p class="dialog_between"><span>应付金额：</span><span class="color_red">¥{{courseMap.totalPrices}}</span></p> -->
                <p class="line"></p>
                <p class="dialog_between"><span>微信扫码：</span></p>
                <div id="qrcode" ref='qrcode'  class="dialog_center dialog_img" style="width: 100%;display: flex;justify-content: center;"></div>
                <p class="dialog_center">请使用微信扫码，支付成功后自动开通服务</p>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="tonext"> 我已完成支付 </el-button>
                </span>
            </el-dialog>
        </div>
        <t_footer></t_footer>
    </div>
</template>

<script>
import t_header from '@/components/t_header/index.vue'
import t_footer from '@/components/t_footer/index.vue'
import { member_pay } from "@/api/other";
import { order_status } from "@/api/course";
export default {
    name:'vipOrder',
    components: {
        t_header,
        t_footer,
    },
    data(){
        return{
            checked:false,
            payTypeList:[{
                    id:1,
                    name:'支付宝支付'
                },{
                    id:2,
                    name:'微信支付'
                },
                // {
                //     id:'3',
                //     name:'京东支付'
                // },
                // {
                //     id:4,
                //     name:'网银支付'
                // }
            ],
            payTypeIndex:1,
            num:0,
            vipInfo:{},
            serviceId:'',
            centerDialogVisible:false,
            title:'',
            newDate:'',
            endDate:'',
            isUserInfo:false,
            storedata:{}
        }
    },
    mounted(){
        this.storedata=JSON.parse(localStorage.getItem("storedata"))
        this.vipInfo=JSON.parse(this.$route.query.item)
        this.num=this.$route.query.num
        this.title=this.$route.query.title
        this.newDate=new Date().toLocaleString();
        // this.getCurrentTime()
        this.jisuanshijian();
    },
    methods:{
        // 父传子
        getTodos(e){
            console.log(e)
            this.isUserInfo=e
        },
        tonext(){
            this.centerDialogVisible=false
        },
        // 切换支付方式
        tabPayType(item){
            this.payTypeIndex=item.id
        },
        pay(){
            var that=this
            var payWay=''
            if(that.payTypeIndex==1){
                payWay='支付宝'
            }else if(that.payTypeIndex==2){
                payWay='微信'
            }else if(that.payTypeIndex==3){
                payWay='京东'
            }else{
                payWay='网银'
            }
            var obj={
                memberItemId:that.vipInfo.memberItemId,
                payWay:payWay
            }
            member_pay(obj).then(res=>{
                console.log(res)
                if(res.data.code==0){
                    if(that.payTypeIndex==1){
                        const div = document.createElement('div')//创建div
                        div.innerHTML = res.data.data.aliSign
                        document.body.appendChild(div)
                        console.log(document.body)
                        document.forms[0].submit();
                    }else if(that.payTypeIndex==2){
                        that.centerDialogVisible=true
                        console.log(res.data.data.codeUrl)
                        that.serviceId=res.data.data.serviceId
                        setTimeout(()=>{
                            that.$QRCodeSC(res.data.data.codeUrl)
                        },1000)
                        that.order_status()
                    }else if(that.payTypeIndex==4){
                        window.location.href=res.data.data.url
                        // that.$router.push({
                        //     path:res.data.data.url,
                        //     query:{}
                        // })
                    }
                    console.log('支付成功')
                }else{
                    that.$message.error(res.data.msg)
                }
            }).catch(error=>{
                console.log(error)
            })
        },
        // 获取订单状态
        order_status(){
            var obj={
                serviceId:this.serviceId,
                serviceType:3,
            }
            order_status(obj).then(res=>{
                console.log(res)
                if(res.data.data==1){
                    this.$message.success('支付成功')
                    this.centerDialogVisible=false
                    setTimeout(()=>{
                        this.$router.push({
                            path:'/ask',
                            query:{
                                type:1
                            }
                        })
                    },1000)
                }else{
                    setTimeout(()=>{
                        this.order_status(obj)
                    },2000)
                }
            }).catch(error=>{
                console.log(error)
            })
        },
        jisuanshijian(){
            var that = this;
            if(that.num==1){
               var nowDate = new Date();
               var rDate = new Date();
               rDate.setMonth(nowDate.getMonth()+1);
               that.endDate = rDate.toLocaleString();
            }else if(that.num==2){
               var nowDate = new Date();
               var rDate = new Date();
               rDate.setMonth(nowDate.getMonth()+3);
               that.endDate = rDate.toLocaleString();
            }else{
                var nowDate = new Date();
                var rDate = new Date();
                rDate.setMonth(nowDate.getMonth()+12);
                that.endDate = rDate.toLocaleString();
            }
        },
        goumaixieyi(){
             let routeData = this.$router.resolve({
                    path: '/html', query: {name:'问答会员协议'} 
                }); 
                window.open(routeData.href, '_blank');
            
        },
        
    }
}
</script>

<style>

</style>